.back-button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.back-button:hover {
    background-color: #e0e0e0;
}
